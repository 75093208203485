// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-audioarchiv-js": () => import("./../../../src/pages/app/audioarchiv.js" /* webpackChunkName: "component---src-pages-app-audioarchiv-js" */),
  "component---src-pages-app-faq-js": () => import("./../../../src/pages/app/faq.js" /* webpackChunkName: "component---src-pages-app-faq-js" */),
  "component---src-pages-app-gaby-js": () => import("./../../../src/pages/app/gaby.js" /* webpackChunkName: "component---src-pages-app-gaby-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-app-karl-js": () => import("./../../../src/pages/app/karl.js" /* webpackChunkName: "component---src-pages-app-karl-js" */),
  "component---src-pages-app-kloesschen-js": () => import("./../../../src/pages/app/kloesschen.js" /* webpackChunkName: "component---src-pages-app-kloesschen-js" */),
  "component---src-pages-app-tim-js": () => import("./../../../src/pages/app/tim.js" /* webpackChunkName: "component---src-pages-app-tim-js" */),
  "component---src-pages-app-urkunde-js": () => import("./../../../src/pages/app/urkunde.js" /* webpackChunkName: "component---src-pages-app-urkunde-js" */),
  "component---src-pages-app-verbrecherkartei-js": () => import("./../../../src/pages/app/verbrecherkartei.js" /* webpackChunkName: "component---src-pages-app-verbrecherkartei-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

