/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import React from "react";
import { Global, ThemeProvider, css, withTheme } from "@emotion/react";
import config from "./gatsby-config";
import theme from "./src/styles/theme";
import Watcher from "./src/services/watcher";

import Layout from "./src/components/Layout";
import "modern-normalize/modern-normalize.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./src/styles/fonts.scss";

const GlobalStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      body {
        margin: 0;
        color: ${theme.color.text};
        background: ${theme.color.spot1};
        font-family: "Copy", sans-serif;
        font-size: 18px;
        line-height: 24px;

        ${theme.funcs.mediaquery(
          "xl",
          `
          font-size: 24px;
          line-height: 32px;
        `
        )}
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        margin: 0;
      }

      a {
        color: currentColor;
      }

      a,
      button {
        cursor: pointer;
      }

      a,
      input,
      button {
        &:focus:not(:focus-visible) {
          outline: none;
        }
      }

      #modalPortal {
        position: relative;
        z-index: 1000;
      }
    `}
  />
));

export const wrapRootElement = ({ element }) => {
  let watchers = {};
  if (typeof window !== "undefined") {
    watchers.scroll = new Watcher(() => window.pageYOffset);
    watchers.size = new Watcher(() => [window.innerWidth, window.innerHeight]);
    window.__WATCHERS__ = watchers;
  }
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  );
};

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const onRenderBody = ({
  setHeadComponents,
  setPreBodyComponents,
  setPostBodyComponents,
}) => {
  const {
    siteMetadata: { gtmId },
  } = config;
  setHeadComponents([
    <script
      key="gtmHead"
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`,
      }}
    />,
  ]);
  setPreBodyComponents([
    <noscript
      key="gtmPreBody"
      dangerouslySetInnerHTML={{
        __html: `
    <iframe
    src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
    height="0"
    width="0"
    style="display:none; visibility: hidden;"
  ></iframe>
    `,
      }}
    />,
  ]);
  setPostBodyComponents([<div key="modalPortal" id="modalPortal"></div>]);
};
