/** @jsx jsx */
import { jsx, css, withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

import Wrapper from "./Wrapper";
import { links } from "../config";
import { Link } from "gatsby";

const StyledLink = styled.a`
  display: inline-block;
  padding: 14px;
  color: currentColor;
  text-decoration: none;
  font-size: 16px;
  line-height: 9px;
  &:hover {
    text-decoration: underline;
  }
`;

const Footer = withTheme(({ theme }) => (
  <footer
    css={css`
      position: relative;
      z-index: 100;
      background: ${theme.color["spot1-light"]};
      padding: 33px 0 21px;
    `}
  >
    <Wrapper>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          ${theme.funcs.mediaquery(
            "xxl",
            `
            justify-content: flex-start;
          flex-wrap: nowrap;
              `
          )}
        `}
      >
        <div>
          <StaticImage
            src="../images/tkkg-logo.png"
            layout="constrained"
            width={120}
            formats={["png"]}
            placeholder=""
            alt="TKKG"
            css={css`
              display: block;
              width: 80px !important;

              ${theme.funcs.mediaquery(
                "lg",
                `
                  width: 120px !important;
              `
              )}
            `}
          />
        </div>
        <ul
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0;
            ${theme.funcs.mediaquery(
              "xl",
              `
              margin-top: -3px;
              padding: 0 35px;
              justify-content: flex-start;
              `
            )}
          `}
        >
          <li>
            <StyledLink
              href={links.contact}
              target="_blank"
              rel="noopener noreferrer"
            >
              Kontakt
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href={links.imprint}
              target="_blank"
              rel="noopener noreferrer"
            >
              Impressum
            </StyledLink>
          </li>
          <li>
            <StyledLink to={links.faq} as={Link}>
              FAQs
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href={links.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href={links.kiNutzungsbedingungen}
              target="_blank"
              rel="noopener noreferrer"
            >
              KI-Nutzungsbedingungen
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href={links.termsAndConditions}
              target="_blank"
              rel="noopener noreferrer"
            >
              Allgemeine Nutzungsbedingungen
            </StyledLink>
          </li>
        </ul>
      </div>
      <StaticImage
        src="../images/linie.png"
        formats={["png"]}
        layout="fullWidth"
        alt=""
        aria-hidden="true"
        css={css`
          margin: 30px 0 20px;
          min-height: 1px;

          img {
            object-fit: fill !important;
          }
        `}
      />
      <p
        css={css`
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          ${theme.funcs.mediaquery(
            "xxl",
            `
                text-align: left;
              `
          )}
        `}
      >
        Copyright © 2024 Sony Music Entertainment Germany GmbH. All Rights
        Reserved
      </p>
    </Wrapper>
  </footer>
));

export default Footer;
