const links = {
  contact: "https://www.tkkg.de/service/kontakt",
  termsAndConditions:
    "https://www.tkkg.de/service/allgemeine-teilnahmebedingungen",
  privacyPolicy: "https://www.tkkg.de/service/datenschutz",
  app: "/app",
  faq: "/app/faq",
  imprint: "https://www.play-europa.de/service/impressum",
  kiNutzungsbedingungen: "https://www.sonymusic.de/ki-nutzungsbedingungen/",
  newsletter:
    "https://forms.sonymusicfans.com/?forms=tkkg_newslettersignup_2017-07-20%2F&ss=0",
  alexaSkill: "https://alexa-skills.amazon.de/apis/custom/skills/amzn1.ask.skill.a46d811e-b4ea-4650-bf5c-669fce90d5ec/launch",
};

export { links };

// all passwords encrypted with window.btoa()
export const achievements = [
  {
    level: "spuernase",
    name: "Spürnase",
    password: "MjA0", // 204
  },
  {
    level: "juniordetektiv",
    name: "Juniordetektiv",
    password: "MTc3", // 177
  },
  {
    level: "profiermittler",
    name: "Profi-Ermittler",
    password: "ODkz", // 893
  },
  {
    level: "meisterdetektiv",
    name: "Meisterdetektiv",
    password: "NjAx", // 601
  },
];

// all passwords encrypted with window.btoa()
const app = {
  rootPath: "/app",
  routes: [
    {
      name: "Tim",
      path: "/app/tim",
      pageTitle: "Tims Daten",
      password: "dGFyemFu", // Tarzan
      authMessage: `Tims Daten sind geschützt!
      Bitte gib ein Passwort ein.`,
      fileImage: "ordner-tim",
    },
    {
      name: "Karl",
      path: "/app/karl",
      pageTitle: "Karls Daten",
      password: "ZWluc3RlaW4=", // Einstein
      authMessage: `Karls Daten sind geschützt!
      Bitte gib ein Passwort ein.`,
      fileImage: "ordner-karl",
    },
    {
      name: "Gaby",
      path: "/app/gaby",
      pageTitle: "Gabys Daten",
      password: "cG9saXplaQ==", // Polizei
      authMessage: `Gabys Daten sind geschützt!
      Bitte gib ein Passwort ein.`,
      fileImage: "ordner-gaby",
    },
    {
      name: "Klößchen",
      path: "/app/kloesschen",
      pageTitle: "Klößchens Daten",
      password: "c2Nob2tvbGFkZQ==", // Schokolade
      authMessage: `Klößchens Daten sind geschützt!
      Bitte gib ein Passwort ein.`,
      fileImage: "ordner-kloesschen",
    },
    {
      name: "Audioarchiv",
      path: "/app/audioarchiv",
      pageTitle: "Audioarchiv",
      password: "dHJvbW1lbGZlbGw=", // Trommelfell
      authMessage: `Das Audioarchiv ist geschützt!
      Bitte gib ein Passwort ein.`,
      fileImage: "ordner-audioarchiv",
      content: [
        {
          name: "Audio-Tagebuch",
          password: "Nzc3", // 777,
          authMessage: `Das Audio-Tagebuch ist geschützt!
          Bitte gib ein Passwort ein.`,
          path: "/audio-tagebuch.mp3",
        },
        {
          name: "Lieblingssong",
          path: "/lieblingssong.mp3",
        },
      ],
    },
    {
      name: "Verbrecher&shy;kartei",
      path: "/app/verbrecherkartei",
      pageTitle: "Verbrecherkartei",
      password: "Z2VoZWltbmlz", // Geheimnis
      authMessage: `Die Verbrecherkartei ist geschützt!
      Bitte gib ein Passwort ein.`,
      fileImage: "ordner-verbrecherkartei",
    },
    {
      name: "Urkunde",
      path: "/app/urkunde",
      pageTitle: "Urkunde",
      authMessage: `Gib den Code für deine Meisterdetektiv-Errungenschaft ein.`,
      achievements,
      fileImage: "ordner-urkunde",
    },
    {
      name: "FAQ",
      path: "/app/faq",
      fileImage: "ordner-faq",
    },
  ],
};

export { app };

const passwords = {
  // get passwords from routes
  ...app.routes
    .filter((r) => r.password)
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.path]: cur.password,
      }),
      {}
    ),
  // get passwords of the achievements (key is "<routepath><achievmentpath>")
  ...achievements.reduce(
    (acc, cur) => ({
      ...acc,
      ["/app/urkunde#" + cur.level]: cur.password,
    }),
    {}
  ),
  ...app.routes
    .filter((r) => r.content)
    .reduce(
      (acc, cur) => ({
        ...acc,
        ...cur.content.reduce((acc2, cur2) => {
          if (cur2.password) {
            return {
              ...acc2,
              [cur.path + "#" + cur2.path]: cur2.password,
            };
          }
          return acc2;
        }, {}),
      }),
      {}
    ),
};

export { passwords };

const urkunde = {
  defaultText: "Dein Name",
};

export { urkunde };

const faq = [
  {
    q: "Was ist „TKKG – Mein Abenteuer?“",
    a:
      "„TKKG – Mein Abenteuer“ ist ein interaktives Hörspiel-Abenteuer mit Rätsel-Elementen und ist spielbar über deinen Amazon Alexa Sprachassistenten. Du übernimmst im Alexa Skill die Rolle des Online-Agenten, der an TKKGs Meisterdetektiv-Programm teilnimmt und sie bei der wichtigen Detektivarbeit unterstützt. Gemeinsam mit TKKG triffst du wichtige Entscheidungen und löst knifflige Rätsel und Aufgaben. Du kannst den Skill <a href='https://www.amazon.de/dp/B096857XGZ/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=tkkg&qid=1622453515&s=digital-skills&sr=1-1'>HIER</a> im Amazon Skill Store aktivieren und findest dort Informationen zum Skill.",
  },
  {
    q: "Wie kann ich „TKKG – Mein Abenteuer“ spielen?",
    a:
      "Um den Alexa Skill „TKKG – Mein Abenteuer“ zu spielen, benötigst du ein Amazon Alexa Gerät (zum Beispiel einen Echo Dot, Echo Show oder auch einen FireTV Stick mit Amazon Alexa Steuerung). Das Gerät muss mit deinem Amazon-Konto verknüpft sein. Alternativ kannst du dir auch die Alexa App auf deinem Smartphone installieren und dich dort mit deinem Amazon-Konto einloggen.<br><br>Wenn du alle Voraussetzungen erfüllt hast, sage einfach „Alexa, aktiviere TKKG Mein Abenteuer“ oder klicke den folgenden <a href='https://alexa-skills.amazon.de/apis/custom/skills/amzn1.ask.skill.a46d811e-b4ea-4650-bf5c-669fce90d5ec/launch'>Link</a>, um den Skill auf einem Alexa-Gerät deiner Wahl zu starten.<br><br>Wenn das nicht klappt, findest du hier eine schrittweise Anleitung:<br>1. Logge dich mit deinem Alexa/Amazon-Konto in der App oder im Browser unter amazon.de ein.<br>2. <a href='https://www.amazon.de/dp/B096857XGZ/ref=sr_1_1?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=tkkg&qid=1622453515&s=digital-skills&sr=1-1'>Hier</a> im Amazon Skill Store findest du den Alexa Skill zu „TKKG – Mein Abenteuer“.<br>3. Du kannst ihn ganz einfach auch über die Suchleiste auf amazon.de finden. Filtere dazu einfach nach „Alexa Skills“ und gib dann „TKKG – Mein Abenteuer“ in die Suchleiste ein.<br>4. Aktiviere den Skill „TKKG – Mein Abenteuer“ im Skill Store, indem du auf den Button „Aktivieren“ klickst.<br>5. Bereit? Dann starte den Skill an deinem Alexa-Gerät oder in der Alexa App mit der Aufforderung „Alexa, starte TKKG Mein Abenteuer“, oder klicke einfach auf folgenden <a href='https://alexa-skills.amazon.de/apis/custom/skills/amzn1.ask.skill.a46d811e-b4ea-4650-bf5c-669fce90d5ec/launch'>Link</a>."
  },
  {
    q: "Kann ich auf mehreren Geräten gleichzeitig spielen?",
    a:
      "Du kannst den Skill an allen Alexa-Geräten spielen, die mit demselben Amazon-Konto verknüpft sind. Allerdings kannst du den Skill nicht zur gleichen Zeit an mehreren Geräten spielen. Um das Gerät zu wechseln, beende den Alexa Skill einfach mit dem Befehl „Alexa Stopp“ und starte ihn dann erneut an deinem gewünschten Gerät mit der Aufforderung „Alexa, starte TKKG Mein Abenteuer“. Nur so ist gewährleistet, dass du deinen Fortschritt im Fall nicht verlierst.",
  },
  {
    q: "Was ist „TKKG – Die Goldene Maske“?",
    a:
      "„Die Goldene Maske“ ist TKKGs erster Fall im Alexa Skill „TKKG – Mein Abenteuer“. TKKG beziehen dich im Rahmen ihres Meisterdetektiv-Programms – ein Programm für Nachwuchsdetektive – als Online-Agent*in in ihre Ermittlungen mit ein. Im Laufe des Falls hinterlassen sie auf der Webseite www.tkkg-mein-abenteuer.de wichtige Hinweise, Beweisstücke und Indizien für dich, die du sichten und auswerten kannst, um schließlich die richtigen Entscheidungen zu treffen und Rätsel zu lösen.<br><br>Der Fall besteht zum einen aus einem klassischen TKKG-Hörspiel, bei dem du gut aufpassen solltest, um wertvolle Hinweise zum Lösen des Falls nicht zu verpassen. Zum anderen übernimmst du eine aktive Rolle als TKKGs Online-Agent*in. Interaktiv hilfst du ihnen mit deinen Antworten beim Lösen des Falls, triffst eigene Entscheidungen und löst Aufgaben und Rätsel. Deine Entscheidungen beeinflussen den Fall maßgeblich, und du gestaltest den weiteren Verlauf aktiv mit.",
  },
  {
    q: "Worum geht es im Fall „Die goldene Maske“?",
    a:
      "Eigentlich wollten TKKG nachts auf dem alten, verlassenen Friedhof nur Fledermäuse für ihr Bio-Projekt beobachten, doch dann fallen plötzlich Schüsse, und der Schütze entkommt.<br>Die Spuren führen TKKG zum Enkel des renommierten und längst verstorbenen Archäologie-Professors Theodor Oldenburg. Dieser berichtet davon, vor Kurzem das Tagebuch seines Großvaters entdeckt zu haben, in dem die Rede von einer wertvollen Azteken-Maske ist, die sein Großvater illegalerweise nach Deutschland überführt und an einem geheimen Ort versteckt haben soll.<br>Aber kann das sein? Gibt es die goldene Maske der Azteken wirklich? Und was bedeuten die geheimnisvollen Karten mit den Rätseln im Tagebuch?<br>Alles deutet auf eine geheimnisvolle Katakombe hin, die der Großvater gebaut haben soll. Wird es TKKG gelingen, das Rätsel um die goldene Maske zu entschlüsseln und die Maske zu finden?",
  },
  {
    q: "Für wen ist der Skill geeignet?",
    a:
      "Der Skill ist für alle Hörspielfans, die auch gern Rätsel und knifflige Aufgaben lösen.<br>Der Skill eignet sich für die ganze Familie, also für Erwachsene und Kinder. Da man schreiben und lesen können muss, empfiehlt sich für die Nutzung des Skills mit jüngeren Kindern die Anwesenheit eines Erwachsenen.",
  },
  {
    q: "Was brauche ich zum Lösen des Falls „TKKG – Die Goldene Maske“",
    a:
      "Zum Lösen des Falls „Die Goldene Maske“ brauchst du Internetzugang, um die Webseite des Meisterdetektiv-Programms www.tkkg-mein-abenteuer.de zu öffnen.<br>TKKG legen dort alle Hinweise und Beweisstücke in ihren passwortgeschützten Ordnern für dich ab. Die Passwörter erfährst du über das Hörspiel im Skill. Also gut aufpassen! Im Laufe des Falls fordern dich TKKG immer wieder dazu auf, auf die Website zu gehen, um in den Ordnern nach Hinweisen zu recherchieren.<br>Da du die Website immer wieder brauchen wirst, ist es am besten, du lässt sie die ganze Zeit in deinem Browser-Fenster geöffnet.<br>Außerdem benötigst du einen <strong>Stift</strong> und einen <strong>Zettel</strong>, um dir wichtige Notizen zu machen (bspw. für TKKGs Passwörter).<br>Zuletzt brauchst du noch ein <strong>Telefon</strong>.",
  },
  {
    q: "Was kostet der Fall „TKKG – Die Goldene Maske“? Ab wann kostet der Fall etwas?",
    a:
      "Die ersten 30 Minuten des Falls sind kostenlos und dienen dir als Spielprobe. Sollte dir die Spielprobe gefallen haben, kannst du nach Beendigung des kostenlosen Teils weitere zwei Stunden des interaktiven Hörspiels freischalten, indem du den Skill käuflich erwirbst. Alexa wird dich vorher explizit fragen, ob du das tun möchtest. Falls du keine Lust hast, den Fall käuflich zu erwerben, verlasse einfach den Skill. Du kannst den Skill jederzeit wieder öffnen und zu einem späteren Zeitpunkt kaufen.<br><br>Der Skill kostet regulär 5,99 €. Es wird aber zum erstmaligen Launch des Skills eine sogenannte Starterphase für unsere Fans geben, in der der Skill über einen kurzen Zeitraum um 50 % reduziert sein wird und in dieser Zeit nur 2,99 € kostet. Nachdem die Starterphase beendet ist, kostet der Skill 5,99 €.<br><br>Solltest du einen Gutschein für eine kostenlose Nutzung besitzen, ist der Skill nach der Aktivierung natürlich kostenlos für dich. Den Gutschein aktivierst du, indem du auf den Link klickst, den du bekommen hast. Sobald sich der Skill öffnet, sagt dir der Skill, dass dein Gutschein eingelöst wurde. Danach kannst du direkt weiterspielen. Beachte bitte: Jeder Gutscheinlink ist nur einmal einlösbar.",
  },
  {
    q: "Wie kaufe ich den Skill, wenn ich mit der App spiele?",
    a:
      "Du kannst den Skill auch über die Amazon Alexa App aktivieren und spielen. Aber Achtung! Solltest du mit der App spielen und an die „Bezahlschranke“ kommen, musst du Folgendes tun, da du den reinen Kaufprozess nicht innerhalb der App abwickeln kannst.<br><br>Daher musst du die App kurz verlassen und den Kauf innerhalb deines Amazon Accounts in deinem Webbrowser abschließen. Das geht wie folgt:<br>1. Klicke auf den folgenden <a href='https://www.amazon.de/dp/B096876TX1?ref=skillrw_isp_dplnk'>Link</a><br>2. Melde dich mit deinen Amazon Login-Daten an. (Achtung, die Login-Daten müssen mit den Login-Daten der App übereinstimmen!)<br>3. Schließe den Kauf ab. (Die App synchronisiert sich automatisch.)<br>4. Kehre zurück in die Amazon Alexa App und spiele den Fall zu Ende.<br><br>Solltest das nicht klappen oder du benötigst Hilfe, wende dich an <a href='mailto:info@play-europa.de'>info@play-europa.de</a>",
  },
  {
    q: "Wie schalte ich im Alexa Skill den Fall „TKKG – Die Goldene Maske“ frei?",
    a:
      "Nach der 30-minütigen Spielprobe des Falls kannst du den Fall im Skill per Sprachbefehl kaufen (In-Skill-Purchase). Du wirst direkt im Skill gefragt, ob du den Fall kaufen möchtest. Wenn du hier „Ja“ sagst, wird das in deinem Amazon-Konto hinterlegte Zahlungsmittel belastet. Du kannst außerdem jederzeit kaufen, indem du „Alexa, starte TKKG Mein Abenteuer und kaufe den Fall die Goldene Maske“ sagst. Keine Angst, deinen Kauf musst du danach in jedem Fall noch einmal im Skill mit einem „Ja“ bestätigen. Sobald der Kauf erfolgreich war, kannst du direkt mit dem Fall starten.<br><br>Solltest du den Fall fälschlicherweise gekauft haben, dann sage „Alexa, starte TKKG Mein Abenteuer und gib den Fall die Goldene Maske zurück“. Du bekommst dann einen Link in deine Alexa App geschickt. Alles Weitere erfährst du dann durch den Amazon Support.",
  },
  {
    q: "Das Kaufen des Falls „TKKG – Die Goldene Maske“ funktioniert nicht. Was kann ich tun?",
    a:
      "Wenn während des Kaufprozess ein Fehler auftritt, könnte das einen der folgenden Gründe haben:<br>1. Du versuchst, den Fall über dein Smartphone zu kaufen. Das geht leider nicht. Sogenannte „In-Skill-Purchases“ funktionieren nur über Alexa-Geräte.<br>2. Das Produkt ist in deinem Land nicht verfügbar. Bitte überprüfe, dass du gültige Adressdaten in deinem Amazon-Konto hinterlegt hast. Du findest diese unter amazon.de -> Mein Konto -> Ihre Adressen. Gehe sicher, dass du eine deutsche Adresse hinterlegt hast.<br>3. Du hast keine oder keine gültige Bezahlmethode bei deinem Amazon-Konto hinterlegt. Überprüfen kannst du das unter amazon.de -> Mein Konto -> Ihre Zahlungen -> Ihre Zahlungsarten.<br>Sollte keiner dieser Punkte dein Problem lösen, dann warte ein paar Minuten und probiere es erneut. Sollte es am nächsten Tag immer noch nicht funktionieren, kontaktiere bitte den Amazon Support über den „Hilfe und Feedback“-Tab in deiner Alexa App.",
  },
  {
    q: "Was kann ich im Alexa Skill sagen? Welche Befehle sind möglich?",
    a:
      "- Um den Skill zu öffnen, sage „Alexa, starte TKKG Mein Abenteuer“.<br>- Wenn du etwas verpasst hast und es erneut hören möchtest, sage einfach jederzeit „Wiederholen“.<br>- Um deinen Fortschritt im Fall „TKKG – Die Goldene Maske“ zu erfahren, frage „Wo bin ich“",
  },
  {
    q: "Was mache ich, wenn ich die Lösung für ein Rätsel nicht weiß?",
    a:
      "Wenn du die Lösung nicht weißt, kehre trotzdem zurück zum Skill, indem du sagst „Alexa, starte TKKG Mein Abenteuer“. Das Spiel wird an der Stelle in der Geschichte fortgesetzt, an der du den Skill zuletzt verlassen hattest. Du hast immer die Möglichkeit, zu raten oder teilweise auch mit „Ich weiß es nicht“ zu antworten. Du wirst sehen, dass du dann gemeinsam mit TKKG eine Lösung finden wirst, wie es mit dem Fall weitergeht. Es ist noch kein*e Meisterdetektiv*in vom Himmel gefallen.",
  },
  {
    q: "Was ist der Detektiv-Rang?",
    a:
      "Am Ende des Falls erhältst du eine Einordnung, wie gut du dich als Detektiv geschlagen hast, also deinen persönlichen Detektiv-Rang. Der Rang steht also für deine Fähigkeiten als Detektiv. Dein Detektiv-Rang bemisst sich daran, wie viele Rätsel und Entscheidungen du auf Anhieb richtig gelöst und beantwortet hast. Je mehr Rätsel du also beim ersten Versuch richtig gelöst hast, desto höher fällt am Ende der Rang aus. Ob du eine Frage beim ersten Versuch richtig gelöst hast, erkennst du daran, dass ein Glockenton erklingt. Auf der Website www.tkkg-mein-abenteuer.de kannst du dir am Ende eine Urkunde mit deinem erspielten Detektiv-Rang ausdrucken. Um auf den Ordner zugreifen zu können, benötigst du ein Passwort. Dieses teilen dir TKKG am Ende des Hörspiels mit. Passe also auch hier gut auf!",
  },
  {
    q: "Welche Detektiv-Ränge gibt es?",
    a:
      "Es gibt vier Detektiv-Auszeichnungen. Wenn du so gut wie alle Rätsel auf Anhieb gelöst hast, bist du ein/e Meisterdetektiv*in und hast den höchsten Rang erzielt.<br><br>- Meisterdetektiv*in: Du hast 17 oder mehr Rätsel beim ersten Versuch gelöst und dir somit den höchsten Titel verdient! Du bist wahrlich ein/e Meisterdetektiv*in. Herzlichen Glückwunsch zur höchsten Auszeichnung, die du in dem Spiel erzielen konntest!<br><br>- Profi–Ermittler*in: Du hast 11 bis 16 Rätsel auf Anhieb gelöst. Das ist ein sehr gutes Ergebnis! Leider bist du trotzdem ganz knapp an der Auszeichnung zum/zur Meisterdetektiv*in vorbeigeschrammt. Trotzdem starke Leistung, Glückwunsch!<br><br>- Juniordetektiv*in: Du hast mindestens 6 oder höchstens 10 Rätsel beim ersten Versuch gelöst und bist auf dem besten Weg, ein/e Profi-Ermittler*in zu werden. Das war eine gute Leistung, aber für den Titel des/der Meisterdetektivs/Meisterdetektivin musst du noch ein wenig üben.<br><br>- Spürnase: Du hast weniger als sechs Rätsel beim ersten Anlauf gelöst. Mach dir nichts draus, es ist noch kein/e Meister*in vom Himmel gefallen. Probier’s weiter! Bald hast du das Zeug zum/zur Meisterdetektiv*in. Übung macht den/die Meisterdetektiv*in!",
  },
  {
    q: "Woher bekomme ich meine Urkunde? Wo erhalte ich den Freischaltcode?",
    a:
      "Am Ende des Falls erhältst du eine Einordnung, wie gut du dich als Detektiv geschlagen hast, also deinen persönlichen Detektiv-Rang. Der Rang steht also für deine Fähigkeiten als Detektiv. Auf der Website www.tkkg-mein-abenteuer.de kannst du dir am Ende eine Urkunde mit deinem erspielten Detektiv-Rang ausdrucken. Um auf den Ordner zugreifen zu können, benötigst du ein Passwort. Dieses teilen dir TKKG am Ende des Hörspiels mit. Passe also auch hier gut auf! Solltest du deinen Code vergessen haben oder versehentlich überhört haben, findest du dein Passwort auch in der Alexa App auf deinem Smartphone im Bereich „Aktivitäten“. Startest du nach Abschluss des Falls den Skill erneut, kannst du dir deinen Code auch noch mal anhören.",
  },
  {
    q: "Woher bekomme ich die Passwörter für die Ordner auf der Website?",
    a:
      "Tim, Karl, Gaby und Klößchen nennen dir ihre Passwörter im Verlauf des interaktiven Hörspiel-Falls. Notiere dir diese Passwörter auf jeden Fall, damit du sie bei deinen weiteren Recherchen kennst.<br><br>Solltest du das gefragte Passwort mal nicht mehr wissen, kehre einfach zum Skill zurück und beantworte die gestellten Fragen mit „Ich weiß es nicht“. Du bekommst dann noch eine zweite Chance und hörst das Passwort erneut. So bekommst du dann zwar keinen Punkt für deinen Meisterdetektiv-Rang, aber sind wir mal ehrlich: Ein/eine echte/r Meisterdetektiv*in ist so aufmerksam, dass er/sie niemals ein Passwort vergessen würde, weil er es auf jeden Fall aufgeschrieben hat.<br><br>Das Passwort für den Audioarchiv-Ordner kann ohnehin nur von einem/r extrem aufmerksamen Meisterdetektiv*in herausgefunden werden. Aber du wirst dich auch ohne den Zugang zu dem Ordner gut schlagen. Also sei nicht enttäuscht, wenn du dieses nicht herausfinden solltest.<br><br>Wenn du überhaupt nicht weiterkommen solltest, dann schreib uns gern eine E-Mail an <a href='mailto:info@europa-kinderwelt.de'>info@europa-kinderwelt.de</a>",
  },
  {
    q: "Kann ich einen Fall mehrmals spielen?",
    a:
      "Du kannst den Fall so oft spielen, wie du möchtest. Wenn du den Fall zu Ende gespielt hast, starte den Skill einfach erneut, und du wirst gefragt, ob du den kompletten Fall von vorn spielen möchtest. Wenn du „Ja“ sagst, beginnst du wieder bei null, und alle gesammelten Punkte sowie dein Fortschritt im Fall werden zurückgesetzt.",
  },
  {
    q: "Wie kann ich während des Falls wieder von vorn beginnen?",
    a:
      'Solltest du während des Falls deinen Fortschritt löschen wollen, um von vorn zu beginnen, starte den Skill und sage „Ich möchte mein Recht auf Vergessen ausüben“. Bedenke bitte dabei: Deine gesammelten Punkte und dein gesamter Fortschritt im Fall „TKKG – Die Goldene Maske“ gehen dann verloren, und du kannst es nicht mehr rückgängig machen.',
  },
  {
    q: "Wie bewerte ich den Alexa Skill „TKKG – Mein Abenteuer“?",
    a:
      "Wenn dir der Skill gefällt, freuen wir uns natürlich über eine Bewertung im Amazon Skill Store! Hinterlasse deine Bewertung gern <a href='https://www.amazon.de/review/create-review/?ie=UTF8&channel=glance-detail&asin=B096857XGZ'>hier</a>.<br>Solltest du Anmerkungen und Feedbacks zur Verbesserung des Skills haben, schreibe uns gern direkt eine E-Mail an: <a href='mailto:info@europa-kinderwelt.de'>info@europa-kinderwelt.de</a><br>Da das unser erster interaktiver TKKG Fall auf Amazon Alexa ist, sind wir besonders neugierig zu erfahren, wie wir uns verbessern können oder was dir gut gefallen hat.",
  },
  {
    q: "Wie kann ich benachrichtigt werden, wenn es einen neuen Fall gibt?",
    a:
      "Wenn du in Bezug auf TKKG immer auf dem neuesten Stand sein willst und zum Beispiel über weitere interaktive Fälle oder Hörspiele informiert werden möchtest, dann melde dich <a href='https://forms.sonymusicfans.com/?forms=tkkg_newslettersignup_2017-07-20%2F&ss=0'>hier</a> für den TKKG Newsletter an.",
  },
  {
    q: "Wer hat den Skill „TKKG – Mein Abenteuer“ produziert?",
    a:
      "(P) & © 2021, EUROPA – A division of Sony Music Entertainment Germany GmbH<br><br>Konzept, Projektmanagement & Redaktion: Svenja Bartsch (Sony Music Entertainment)<br>Story, Skript & Gamedesign: Martin Hofstetter & Tim Capper nach Motiven von Stefan Wolf<br>Audio- und Hörspielproduktion: Primetime Studio, Hamburg<br>Umsetzung und Programmierung: 169 Labs GmbH (Agentur für Sprachassistenten)<br>Illustrationen: Comicon S.L. nach Artwork-Vorlagen von Reiner Stolte<br>Website: Convoy Interactive GmbH<br>TKKG ist eine eingetragene Marke von Sony Music Entertainment Germany GmbH<br><br>Sprecher:<br>Sascha Draeger als „Tim“<br>Manou Lubowski als „Klößchen“<br>Tobias Diakow als „Karl“<br>Rhea Harder als „Gaby“<br><br>Erzähler: Wolfgang Kaven<br>",
  },
  {
    q: "Hilfe & Kontakt ",
    a:
      "Solltest du Fragen oder Anmerkungen zum Alexa Skill „TKKG – Mein Abenteuer“ haben und keine Hilfe in den FAQs finden, wende dich bitte an: info@europa-kinderwelt.de oder benutze das Formular auf: <a href='https://www.play-europa.de/service/kontakt'>https://www.play-europa.de/service/kontakt</a>",
  },
];

export { faq };
