import React from "react";
import Footer from "./Footer";

const Layout = ({ children }) => (
  <>
    {children}
    <Footer />
  </>
);

export default Layout;
