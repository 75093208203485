const colors = {
  white: "#fff",
  "dark-blue": "#002C78",
  "less-dark-blue": "#1E388E",
  "light-blue": "#D7E0ED",
  yellow: "#FFD900",
  green: "#2FBC2B",
  red: "#FE0000",
};

const breakpoints = [0, 480, 640, 768, 1024, 1280, 1440, 1920];
const breakpointAlias = ["sm", "md", "lg", "xl", "xxl", "x3l", "x4l", "x5l"];
const breakpointByAlias = breakpoints.reduce(
  (accu, value, index) => ({
    ...accu,
    [breakpointAlias[index]]: value,
  }),
  {}
);
const mediaquery = (
  breakpointAlias,
  styles
) => `@media (min-width: ${breakpointByAlias[breakpointAlias]}px) {
${styles}
}`;

const textStyles = {
  headline1: `
    font-family: Headline, Impact, sans-serif;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.03125em;
    color: ${colors["yellow"]};

    ${mediaquery(
      "xl",
      `
        font-size: 64px;
        line-height: 53px;
      `
    )}

  `,
  headline2: `
    font-family: Headline, Impact, sans-serif;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.03125em;
    ${mediaquery(
      "xl",
      `
        font-size: 42px;
        line-height: 53px;
      `
    )}
  `,
  headline3: `
    font-family: Headline, Impact, sans-serif;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.025em;

    ${mediaquery(
      "xl",
      `
        font-size: 32px;
        line-height: 40px;
      `
    )}
  `,
  headline4: `
    font-family: Headline, Impact, sans-serif;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.025em;

    ${mediaquery(
      "xl",
      `
        font-size: 28px;
        line-height: 36px;
      `
    )}
`,
  bodySmall: `
  font-size: 18px;
  line-height: 22px;
`,
};

const theme = {
  color: {
    ...colors,
    spot1: colors["dark-blue"],
    "spot1-light": colors["less-dark-blue"],
    spot2: colors["yellow"],
    text: colors["white"],
    success: colors["green"],
    error: colors["red"],
  },
  grid: {
    gutter: 40,
  },
  breakpointAlias,
  funcs: {
    mediaquery,
  },
  textStyles,
};

export default theme;
